import { useEffect, useState } from "react";
import { validContainerNumber, validChassisNumber, validUsdotNumber, validTruckLicense } from "../services/app.service";
import { Button } from "./Button";
import { SearchInputPlain } from "./SearchInputPlain";
import { SelectInput } from "./SelectInput";

// For state management
import { useDispatch, useSelector } from "react-redux";
import { reset, setQuery, setQueryType, setTimePeriod, TIME_PERIODS } from "../reducer-slices/search.slice";

export const SearchEquipment = ({
  onSearch,
  loading = false
}) => {

  // Redux 
  const dispatch = useDispatch();
  const searchQuery = useSelector((state) => state.search);

  // To show loading indicator on button
  const [isLoading, setIsLoading] = useState(false);

  const timePeriod = [
    { label: "Last 24 Hours", value: "Last 24 Hours" },
    { label: "One Week", value: "One Week" },
    { label: "One Month", value: "One Month" },
    { label: "Last Quarter", value: "Last Quarter" },
    { label: "anytime", value: "anytime" },
  ];

  // Helper functions
  const setQueryTypeFromQuery = (query) => {
    if (query[3] === 'U') {
      dispatch(setQueryType("container"));
    } else if (query[3] === 'Z') {
      dispatch(setQueryType("chassis"));
    } else if (query.substring(0,5) === "USDOT"){
      dispatch(setQueryType("usdot"));
    } else if (query.length <= 8){
      dispatch(setQueryType("truck-license"));
    } else {
      dispatch(setQueryType(""));
    }
  }

  const isValidQuery = () => {
    let type = searchQuery.queryType;
    if (type === "container") {
      return validContainerNumber(searchQuery.query);
    } else if (type === "chassis") {
      return validChassisNumber(searchQuery.query);
    } else if (type === "usdot"){
      // ensure USDOT includes a space
      dispatch(setQuery("USDOT " + searchQuery.query.replace(/\D/g, "")));
      return validUsdotNumber(searchQuery.query);
    } else if (type === "truck-license") {
      return validTruckLicense(searchQuery.query);
    } else {
      return false;
    }
  }

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        if (searchQuery.query && isValidQuery()) {
          onSearch(searchQuery);
        }
      }}
    >
      <div className="flex justify-center items-end mt-12 mb-16">
        {/* Container number */}
        <div className="mx-2 intro-y">
          <SearchInputPlain
            label="Search"
            placeholder="e.g ECMU4920643"
            error={!isValidQuery}
            value={searchQuery.query}
            onBlur={(query) => {
              setQueryTypeFromQuery(query);
              dispatch(setQuery(query.replace(/\s/g, "")));
            }}
            onChange={(query) => {
              setQueryTypeFromQuery(query);
              dispatch(setQuery(query.replace(/\s/g, "")));
            }}
          />
          {!isValidQuery() && searchQuery.query != "" && (
            <div className="absolute mt-1.5 text-red-600 text-sm">
              <span className="intro-y">
                {" "}
                Oops! Invalid query. Please check it and try again!
              </span>
            </div>
          )}
        </div>

        {/* Time Period */}
        <div className="mx-2 intro-y">
          <SelectInput
            placeholder="anytime"
            label="Time Period"
            options={timePeriod}
            required={false}
            onSelect={(item) => {
              let period = item.value?.toLowerCase();

              if (period === "last 24 hours") {
                dispatch(setTimePeriod(TIME_PERIODS.last24Hours));

              } else if (period === "one week") {
                dispatch(setTimePeriod(TIME_PERIODS.oneWeek));

              } else if (period === "one month") {
                dispatch(setTimePeriod(TIME_PERIODS.oneMonth));
              } else if (period === "last quarter") {
                dispatch(setTimePeriod(TIME_PERIODS.lastQuarter));
              } else if (period === "anytime") {
                dispatch(setTimePeriod(TIME_PERIODS.anytime));
              }
            }}
          />
        </div>

        {/* Search Button */}
        <div className="mx-2 intro-y">
          <Button title="Search" isLoading={isLoading} />
        </div>
      </div>
    </form>
  );
};
