import { createSlice } from "@reduxjs/toolkit";

export const TIME_PERIODS = {
    last24Hours: {
        timePeriod: "Last 24 Hours",
        beginDate: new Date(
            new Date().setDate(new Date().getDate() - 1)
        ).toISOString(),
        endDate: new Date().toISOString(),
    },
    oneWeek: {
        timePeriod: "One Week",
        beginDate: new Date(
            new Date().setDate(new Date().getDate() - 7)
        ).toISOString(),
        endDate: new Date().toISOString(),
    },
    oneMonth: {
        timePeriod: "One Month",
        beginDate: new Date(
            new Date().setMonth(new Date().getMonth() - 1)
        ).toISOString(),
        endDate: new Date().toISOString(),
    },
    lastQuarter: {
        timePeriod: "Last Quarter",
        beginDate: new Date(
            new Date().setMonth(new Date().getMonth() - 3)
        ).toISOString(),
        endDate: new Date().toISOString(),
    },
    lastSixMonths: {
        timePeriod: "Last Six Months",
        beginDate: new Date(
            new Date().setMonth(new Date().getMonth() - 6)
        ).toISOString(),
        endDate: new Date().toISOString(),
    },
    anytime: {
        timePeriod: "anytime",
        beginDate: new Date(
            new Date().setMonth(new Date().getMonth() - 72) // was 6 months made 6 years and anytime 
        ).toISOString(),
        endDate: new Date().toISOString(),
    },
};

export const searchSlice = createSlice({
    name: "search",
    initialState: {
        query: "",
        queryType: "",
        timePeriod: "",
        beginDate: "",
        endDate: "",
    },
    reducers: {
        setQuery: (state, actions) => {
            state.query = actions.payload;
        },
        setQueryType: (state, actions) => {
            state.queryType = actions.payload;
        },
        setTimePeriod: (state, actions) => {
            state.timePeriod = actions.payload.timePeriod;
            state.beginDate = actions.payload.beginDate;
            state.endDate = actions.payload.endDate;
        },
        reset: (state) => {
            state.query = "";
            state.queryType = "";
            state.timePeriod = "";
            state.beginDate = "";
            state.endDate = "";
        }
    }
});

export const { setQuery, setQueryType, setTimePeriod, reset } = searchSlice.actions;

export default searchSlice.reducer;