import { toast } from "react-toastify";
import { API_BASE_URL } from "./baseUrl";

export class AuthService {
  /**
   * Calls the API endpoint for adding a new User record
   * @param record User Object to add
   * @param callback Callback function
   */
  static Signup = (record, callback) => {
    if (record) {
      API_BASE_URL.post("/user", record, {
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          if (res.status === 201 && res.data.ResponseMetadata.RequestId) {
            callback();
            return;
          } else if (res.data.body.error) {
            toast.error(res.data.body.error);
            return;
          }
        })
        .catch((err) => {
          callback("error");
          if (
            err.response?.data?.error
              ?.toLowerCase()
              .includes("user already exists")
          ) {
            toast.error("User already exists");
            return;
          }
          toast.error(err.message);
        });
    } else {
      callback("error");
      toast.error("All fields are required!");
    }
  };

  /**
   * Calls the API endpoint for Logging in a User
   * @param username Username or email of User to login
   * @param password SHA256 hashed Password of User to login
   * @param callback Callback function
   */
  static Login = async (username, password, callback) => {
    if (!username || !password) {
      return false
    }

    API_BASE_URL.post(
      "/user/verify",
      { username, password },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => {
        if (res.status === 200) {
          AuthService.SetLocalStorage("access-token", res.data["access-token"]);
          AuthService.SetLocalStorage("expiration-datetime-epoch", res.data["expiration-datetime-epoch"]);
          callback();
        } else {
          callback("error");
          toast.error("Invalid Credentials");
        }
      })
      .catch((err) => {
        toast.error(err.response.data.error || err.message);
        callback("error");
      });

  };

  /**
   * Get item from LocalStorage
   * @param key The key of the item to fetch
   */
  static GetLocalStorage = (key) => {
    if (key) return localStorage.getItem(key);
    return "";
  };

  /**
   * Save item to LocalStorage
   * @param key The key of the item to save
   * @param item The item to save to localStorage
   */
  static SetLocalStorage = (key, item) => {
    localStorage.setItem(key, item);
    if (localStorage.getItem(key)) return true;
    else return false;
  };

  /**
   * Remove item from LocalStorage
   * @param key The key of the item to remove
   */
  static RemoveLocalStorageItem = (key) => {
    localStorage.removeItem(key);
    if (localStorage.getItem(key)) return false;
    else return true;
  };

  static SetCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires;
  }

  static GetCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }
}
